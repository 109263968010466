import * as React from "react";
import { useLocation } from "@reach/router";
import { Button, TextField } from "@material-ui/core";
import { useUser } from "../features/users/hooks";

export const FeedbackForm = () => {
    const location = useLocation();
    const user = useUser();
    const userId = user && user.data && Number(user.data.data.id);
    const [status, setStatus] = React.useState(``);

    if (status === `SUCCESS`) return <p>Thank you for your feedback!</p>;
    if (status === `ERROR`) return <p>Oops! There was an error.</p>;

    return (
        <form
            action="https://formspree.io/xaypvkav"
            method="POST"
            onSubmit={submitForm}
        >
            <input name="user_id" type="hidden" value={userId || `unknown`} />
            <input
                name="location"
                type="hidden"
                value={location && location.href ? location.href : `unknown`}
            />
            <TextField
                fullWidth
                id="message"
                label="How can we make this page better?"
                margin="none"
                maxRows={2}
                name="message"
                placeholder=""
                required
                variant="outlined"
            />
            <Button fullWidth type="submit">
                Send Feedback
            </Button>
        </form>
    );

    /* https://formspree.io/forms/xaypvkav/integration */
    function submitForm(ev) {
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader(`Accept`, `application/json`);
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                setStatus(`SUCCESS`);
            } else {
                setStatus(`ERROR`);
            }
        };
        xhr.send(data);
    }
};

import * as React from "react";
import { IconButton } from "@material-ui/core";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

export const SocialFollow = () => {
    return (
        <div className="text-center">
            <p className="uppercase">Connect with us</p>
            <IconButton
                aria-label="Follow CCG Trader on Facebook"
                href="https://www.facebook.com/ccgtrader"
                target="_blank"
            >
                <FacebookIcon />
            </IconButton>
            <IconButton
                aria-label="Follow CCG Trader on Twitter"
                href="https://twitter.com/ccgtrader"
                target="_blank"
            >
                <TwitterIcon />
            </IconButton>
            <IconButton
                aria-label="Follow CCG Trader on Instagram"
                href="https://www.instagram.com/ccgtrader/"
                target="_blank"
            >
                <InstagramIcon />
            </IconButton>
        </div>
    );
};
